var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("List of GPS Devices of "+_vm._s(_vm.cName))])]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.userType.userType === 'gps_service_provider'
                    ? _vm.AdminRouter.Root
                    : _vm.AdminRouter.GPSServiceList}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v(" Back ")])],1),(
                  _vm.userType.userType === 'gps_service_provider' &&
                    _vm.approvalStatus === 'Approved'
                )?_c('router-link',{staticClass:"marginRight",attrs:{"to":_vm.AdminRouter.GPSDeviceRegister}},[_c('el-button',{attrs:{"type":"primary el-button--mini","round":""}},[_c('i',{staticClass:"el-icon-circle-plus"}),_vm._v(" Add GPS Device ")])],1):_vm._e()],1)]),_c('div',[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true}},[_c('el-form-item',{class:{
                  'is-error': _vm.fieldHasErrors(''),
                  'is-required': _vm.isFieldRequired(''),
                },attrs:{"label":"Name"}},[_c('el-input',{attrs:{"placeholder":"Device Name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),(_vm.errors.has(''))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("")))]):_vm._e()],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"success","icon":"el-icon-search","round":""},on:{"click":_vm.filterDevices}},[_vm._v("Search")])],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-refresh","round":""},on:{"click":_vm.clearFilter}},[_vm._v("Clear")])],1)],1)],1),_c('div',[_c('el-table',{attrs:{"data":_vm.qGPSService,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"deviceName","sortable":"","label":"Name"}}),_c('el-table-column',{attrs:{"prop":"gpsProtocolName","sortable":"","label":"Protocol (Port)"}}),(
                  _vm.userType.userType === 'admin' ||
                    _vm.userType.userType === 'dotm_staff' ||
                    _vm.userType.userType === 'gps_service_provider'
                )?_c('el-table-column',{attrs:{"prop":"approvalStatus","sortable":"","label":"Status"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"Action"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                      scope.row.approvalStatus !== 'Approved' &&
                        _vm.userType.userType === 'gps_service_provider'
                    )?_c('router-link',{staticClass:"el-button el-button--primary el-button--mini",attrs:{"to":'/gpsdevice/register?id=' + scope.row.id}},[_c('i',{staticClass:"el-icon-edit-outline",attrs:{"title":"Edit"}})]):_vm._e(),_c('router-link',{staticClass:"el-button el-button--info el-button--mini",attrs:{"to":'/gpsdevice/detail?id=' + scope.row.id}},[_c('i',{staticClass:"el-icon-view",attrs:{"title":"Detail"}})])]}}])})],1),_c('pagination',{attrs:{"data":_vm.gpsDeviceList,"pageSize":10},model:{value:(_vm.qGPSService),callback:function ($$v) {_vm.qGPSService=$$v},expression:"qGPSService"}})],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }