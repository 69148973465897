






















import {
  Vue,
  Component,
  Prop,
  Watch,
  Model,
  Emit
} from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
  @Prop({ default: [] })
  data!: any[];

  @Prop({ default: 2 })
  pageSize!: number;

  @Prop({ default: "" })
  sortOrder!: string;

  @Prop({ default: "" })
  sortProp!: string;

  @Model("input", {
    default: []
  })
  public value!: any[];

  cp = 1; //current page
  tp = 1; //total page
  td = 1; //total data
  private valueInternal: any[] = [];

  @Emit("input")
  public onValueChanged(value: any[]) {
    this.valueInternal = value;
  }

  created() {
    //this._data = this.data;
    this.cp = 1;
    this.tp = 1;
    this.td = 1;
    this.initial();
  }

  get to() {
    let highBound = this.from + this.pageSize;
    if (this.data.length < highBound) {
      highBound = this.td;
    }
    return highBound;
  }
  get from() {
    return this.pageSize * (this.cp - 1);
  }
  queriedData() {
    if (this.data) {
      var md = [...this.data];
      let d = md.slice(this.from, this.to);
      this.onValueChanged(d);
    }
  }
  initial() {
    if (this.data.length > 0) {
      this.td = this.data.length;
      this.tp = Math.ceil(this.td / this.pageSize);

      this.queriedData();
    }
  }

  @Watch("data")
  onDataChanged(val: any[], oldVal: any[]) {
    if (this.data.length > 0) {
      this.td = this.data.length;
      this.tp = Math.ceil(this.td / this.pageSize);
      this.cp = 1;
    }
    this.queriedData();
  }

  @Watch("sortOrder")
  onSortOrderChanged(val: any, oldVal: any) {
    this.data.sort(this.compare);
  }

  mounted() {}

  onPageChange(currentPage: number) {
    this.cp = currentPage;
    this.queriedData();
  }

  compare(a: any, b: any) {
    var prop = this.sortProp;
    var x = a[prop];
    var y = b[prop];
    if (x) {
      x = x.toLowerCase();
    }
    if (y) {
      y = y.toLowerCase();
    }

    if (this.sortOrder === "descending") {
      if (x > y) return -1;
      if (x < y) return 1;
    } else if (this.sortOrder === "ascending") {
      if (x < y) return -1;
      if (x > y) return 1;
    }
    return 0;
  }
}
