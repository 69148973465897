




















































































































































import { Vue, Component } from "vue-property-decorator";
import authStore from "@/store/modules/auth";
import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";
import { UserProfile } from "@/store/models/auth";
import {
  MetaModule as metaStore,
  GPSServiceProviderModule as gpsServiceStore,
  CommonStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
  },
})
export default class DeviceClass extends Vue {
  palikas: any[] = [];
  qGPSService: any[] = [];
  gpsDeviceList: any[] = [];
  districtName: any = [];
  cName: string = "";
  public userType: UserProfile = {} as UserProfile;

  filter = {
    name: "",
    district: "",
    palika: "",
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtList;
  }
  async created() {
    metaStore.loadDis();

    const gpsServiceId = this.$route.query["id"];
    CommonStore.setGpsServiceId(gpsServiceId);
    await gpsServiceStore.getServiceProviderById(gpsServiceId);
    let provider_list = gpsServiceStore.gpsProvider;
    await gpsServiceStore.getGpsDeviceByServiceId(gpsServiceId);
    let device_list = gpsServiceStore.gpsDeviceList;
    this.cName = provider_list.profile.companyName;
    this.userType = authStore.profile;

    if (this.userType.userType === "vehicle_owner") {
      const filteredData = device_list.filter(
        (x) => x.approvalStatus === "Approved"
      );
      this.gpsDeviceList = filteredData;
    } else {
      this.gpsDeviceList = device_list;
    }
  }

  edit(id: any) {
    this.$router.push(AdminRouter.GPSDeviceRegister + "?id=" + id);
  }
  async filterPalika() {
    this.filter.palika = "";
    this.palikas = await metaStore.loadMuncipality(
      parseInt(this.filter.district)
    );
  }
  filterDevices() {
    var filterData = gpsServiceStore.gpsServiceProviderList;

    if (this.filter.name !== "") {
      this.filter.name = this.filter.name.toLowerCase();
      filterData = filterData.filter((x) =>
        x.profile.companyName.toLowerCase().includes(this.filter.name)
      );
    }

    this.gpsDeviceList = filterData;
  }
  clearFilter() {
    this.filter = {
      name: "",
      district: "",
      palika: "",
    };
    this.gpsDeviceList = gpsServiceStore.gpsDeviceList;
  }
  submit(e: any) {
    gpsServiceStore.createGpsDevice();
  }

  get approvalStatus() {
    return gpsServiceStore.gpsProvider.profile.approvalStatus;
  }
}
